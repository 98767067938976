export const PAGE_URLS = {
  HOME: "/",
  ANSWER_SHEET: "/answer-sheet",
  PICTURE_ROUND: "/picture-round",
  MOVIE_ROUND: "/movie-round"
};

export const PAGE_URL_TITLE_MAP = {
  [PAGE_URLS.HOME]: "Welcome to Mick's Bits!",
  [PAGE_URLS.ANSWER_SHEET]: "Answer Sheet",
  [PAGE_URLS.PICTURE_ROUND]: "Picture Round",
  [PAGE_URLS.MOVIE_ROUND]: "Movie Round"
};
