import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import { HomePage } from "./pages/Home";
import { Header } from "./components/Header";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { PAGE_URLS } from "./constants";

import "./App.css";

export function App() {
  return (
    <div className="App">
      <ErrorBoundary>
        <Router>
          <Header />
          <Switch>
            <Route exact path={PAGE_URLS.HOME}>
              <HomePage />
            </Route>
            <Route>
              <Redirect to={PAGE_URLS.HOME} />
            </Route>
          </Switch>
        </Router>
      </ErrorBoundary>
    </div>
  );
}
