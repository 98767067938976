import React from "react";

import "./styles.css";

export function HomePage() {
  return (
    <div className="Home">
      <p style={{ margin: 0 }}>
        Looking for{" "}
        <span style={{ fontWeight: 700, fontSize: "2.4rem" }}>Quiz Bits</span>?
        It's now been moved to{" "}
        <a
          className="Home-quizbits-link"
          href="https://quizbits.uk/"
        >
          quizbits.uk
        </a>
      </p>
      <p>
        Byeeeee....{" "}
        <span role="img" aria-label="Waving Hand Emoji">
          👋
        </span>
      </p>
      <div className="Home-image-wrapper">
        <img
          src="https://media.giphy.com/media/f7tYXicAGamR2/giphy.gif"
          className="Home-image"
          alt="Terminator 2 Thumb gif"
        />
      </div>
    </div>
  );
}
