import React, { CSSProperties } from "react";
import { useLocation, Link } from "react-router-dom";

import { PAGE_URLS, PAGE_URL_TITLE_MAP } from "../constants";
import "./Header.css";

interface HomeLinkProps {
  isHomePage: boolean;
  style?: CSSProperties;
}

function HomeLink({ isHomePage, style }: HomeLinkProps) {
  return isHomePage ? null : (
    <Link to={PAGE_URLS.HOME} className="App-link" style={style}>
      Home
    </Link>
  );
}

export function Header() {
  const { pathname } = useLocation();
  const isHomePage = pathname === PAGE_URLS.HOME;

  return (
    <header
      className="Header"
      style={{
        justifyContent: isHomePage ? "center" : "space-around",
      }}
    >
      <HomeLink isHomePage={isHomePage} />
      <h1 className="Header-title">
        {PAGE_URL_TITLE_MAP[pathname] ?? "Hello!"}
        {isHomePage ? (
          <>
            <br />
            <div style={{ marginTop: "1rem" }}>
              <span
                role="img"
                aria-label="Hugging Face Emoji"
              >
                🤗
              </span>
            </div>
          </>
        ) : null}
      </h1>
      <HomeLink isHomePage={isHomePage} style={{ visibility: "hidden" }} />
    </header>
  );
}
